<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Simulation Calculation List') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <ListFilter @filter="fetchData" />
          </div>
          <div
            class="column has-text-right-mobile is-narrow"
          >
            <router-link
              class="ant-btn ant-btn-lg ant-btn-primary"
              :to="{ name:'simulations-new' }"
            >
              {{ $t('Add Simulation Calculation') }}
            </router-link>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #remarks="{ record }">
            {{ record.remarks || '-' }}
          </template>
          <template #generatedAt="{ record }">
            {{ $filters.date(record.createdAt, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #updatedAt="{ record }">
            {{ $filters.date(record.updatedAt, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #action="{ record }">
            <Modal>
              <template #default="{hide}">
                <DeleteSimulation
                  :simulation="record"
                  @close="()=>{ hide(); fetchData(); }"
                  @cancel="hide"
                />
              </template>
              <template #handler="{show}">
                <DeleteFilled
                  class="has-text-danger"
                  @click.stop.prevent="show"
                />
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/simulations.json"></i18n>

<i18n>
{
  "en": {
    "Simulation Calculation List": "Simulation Calculation List",
    "Add Simulation Calculation": "Add Simulation Calculation",
    "Remarks": "Remarks",
    "Generated at": "Generated at",
    "Updated at": "Updated at",
  },
  "ja": {
    "Simulation Calculation List": "Simulation Calculation List",
    "Add Simulation Calculation": "Add Simulation Calculation",
    "Remarks": "Remarks",
    "Generated at": "Generated at",
    "Updated at": "Updated at",
  }
}
</i18n>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/Modal';
import listTable from '@/components/mixins/list-table';
import ListFilter from '@/views/simulations/components/RequestFilter';
import DeleteSimulation from '@/views/simulations/components/DeleteSimulation';

export default {
  name: 'SimulationList',
  components: {
    Modal,
    ListFilter,
    DeleteSimulation,
    DeleteFilled,
  },
  mixins: [listTable],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Request ID'),
          dataIndex: 'requestId',
          key: 'requestId',
          slots: { customRender: 'requestId' },
        },
        {
          title: this.$t('Version ID'),
          dataIndex: 'version',
          key: 'version',
          slots: { customRender: 'version' },
        },
        {
          title: this.$t('Postal code'),
          dataIndex: 'postalCode',
          key: 'postalCode',
          slots: { customRender: 'postalCode' },
        },
        {
          title: this.$t('Address'),
          dataIndex: 'address',
          key: 'address',
          slots: { customRender: 'address' },
        },
        {
          title: this.$t('Remarks'),
          dataIndex: 'remarks',
          key: 'remarks',
          slots: { customRender: 'remarks' },
        },
        {
          title: this.$t('Generated at'),
          sorter: true,
          dataIndex: 'createdAt',
          key: 'generatedAt',
          slots: { customRender: 'generatedAt' },
        },
        {
          title: this.$t('Updated at'),
          sorter: true,
          defaultSortOrder: 'descend',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          slots: { customRender: 'updatedAt' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {},
  created() {
    this.handleTableChange({}, {}, {
      field: 'updatedAt',
      order: 'descend',
    });
  },
  methods: {
    async handleTableChange(pagination, filters, sorter) {
      await this.prepareTable(pagination, filters, sorter);
      await this.fetchData();
    },
    async fetchData() {
      this.list = undefined;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store.dispatch('simulations/list', this.$route.query).then((resp) => {
        const { data, pagination } = resp;

        this.list = data.map((p, index) => {
          p._id = index + 1;
          return p;
        });

        this.setPagination(pagination);
      }).finally(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({
            name: 'simulations-show',
            params: { id: record.id },
          });
        },
      };
    },
  },
};
</script>
