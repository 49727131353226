<template>
  <div
    class="columns form is-mini-gap is-multiline"
  >
    <div class="column is-narrow">
      <a-input
        v-model:value="requestId"
        class="tablet-200"
        :size="size"
        :placeholder="$t('Request ID')"
        @change="handleFilter"
      />
    </div>
    <div class="column is-narrow">
      <a-input
        v-model:value="postalCode"
        class="tablet-200"
        :size="size"
        :placeholder="$t('Postal code')"
        @change="handleFilter"
      />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        :size="size"
        :format="dateFormat"
        class="tablet-200"
        @change="handleFilter"
      >
        <a-input
          :value="rangeString"
          :placeholder="$t('Generated date range')"
          :size="size"
          readonly
        >
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div
      v-if="requestId || postalCode || range.length"
      class="column"
    >
      <a-button
        :size="size"
        style="min-width: 110px;"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n src="@/locales/components/simulations.json"></i18n>

<i18n>
{
  "en": {
    "Generated date range": "date range",
    "Clear": "Clear"
  },
  "ja": {
    "Generated date range": "日付範囲",
    "Clear": "クリア"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import { cleanObject } from '@/utils/util';

export default {
  name: 'RequestFilter',
  components: { CalendarFilled },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      requestId: undefined,
      postalCode: undefined,
      range: [],
    };
  },
  computed: {
    rangeString() {
      if (this.range.length > 0) {
        const format = this.dateFormat;

        const from = this.$filters.date(this.range[0], { format });
        const to = this.$filters.date(this.range[1], { format });

        return `${from} ~ ${to}`;
      }

      return '';
    },
    formattedRange() {
      const format = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format });
        range.dateTo = this.$filters.date(this.range[1], { format });
      }

      return range;
    },
  },
  mounted() {
    this.requestId = this.$route.query.requestId || undefined;
    this.postalCode = this.$route.query.postalCode || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
  },
  methods: {
    handleFilter: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        postalCode: this.postalCode,
        ...this.formattedRange,
        requestId: this.requestId,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.requestId = undefined;
      this.postalCode = undefined;
      this.range = [];
      this.handleFilter();
    },
  },
};
</script>

<style scoped>

</style>
